@import "../../../mixins";

.about-advantages {
  padding: rem(35) rem(30) 0 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: rem(20);

  @include mediaBigDesktop {
    padding: big(35) big(30) 0 0;
    gap: big(20);
  }

  @include mediaLaptop {
    padding: rem(25) rem(30) 0 0;
    gap: rem(18);
  }

  @include mediaTablet {
    padding: 0 rem(40) 0 0;
  }

  @include mediaMobile {
    padding: 0;
    gap: rem(10);
  }

  &__icon {
    width: rem(44);
    height: rem(44);
    flex-shrink: 0;

    pointer-events: none;
    user-select: none;

    @include mediaBigDesktop {
      width: big(44);
      height: big(44);
    }

    @include mediaDesktop {
      width: rem(32);
      height: rem(32);
    }

    @include mediaLaptop {
      width: rem(28);
      height: rem(28);
    }

    @include mediaMobile {
      width: rem(24);
      height: rem(24);
    }

    & img {
      width: 100%;
      height: 100%;

      object-fit: contain;
      object-position: center;
    }
  }

  &__content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: rem(10);

    @include mediaBigDesktop {
      gap: big(10);
    }

    @include mediaMobile {
      gap: rem(5);
    }
  }

  &__title {
    width: 100%;
    display: block;

    color: var(--text-dark-primary);
    font: var(--font-body-L-sb);
  }

  &__description {
    width: 100%;
    display: block;

    color: var(--text-dark-secondary);
    font: var(--font-body-M-m);
  }
}
